import localforage from 'localforage';
import { makeAutoObservable } from 'mobx';
import {
  hydrateStore,
  isHydrated,
  isPersisting,
  makePersistable,
} from 'mobx-persist-store';

import { AvailablePaymentMethods } from '@/app/[locale]/cart/types';
import { mockedItemsGroups } from '@/mocks/items';
import { mockedMM2ItemsGroups } from '@/mocks/mm2Items';
import { ProductById } from '@/shared/types/common';
import { PaymentMethodIllustrations } from '@/shared/ui/illustration/illustration';

export type CartItem = {
  id: number;
  price: number;
  count: number;
  status: 'unavailable' | null;
};

export type CartCheckoutInfo = {
  isTermsAccepted: boolean;
  isPaymentValid: boolean;
  activePaymentMethod: keyof PaymentMethodIllustrations | null;
  promocode: string;
  promocodePercentValue: string | number;
  availablePaymentMethods: AvailablePaymentMethods[];
  accountInfo: {
    isUsingAccountBalance: boolean;
    accountBalance: number;
  };
  totalSum: number;
};

const mockedAvailablePaymentMethods: AvailablePaymentMethods[] = [
  { tagValue: 3, paymentMethod: 'etherium' },
  { tagValue: 3, paymentMethod: 'visa' },
  { tagValue: 3, paymentMethod: 'u-money' },
  { tagValue: 3, paymentMethod: 'tether' },
  { tagValue: 3, paymentMethod: 'alfa' },
  { tagValue: 3, paymentMethod: 'sber-pay' },
  { tagValue: 3, paymentMethod: 'bitcoin' },
  { tagValue: 3, paymentMethod: 'tron' },
  { tagValue: 3, paymentMethod: 'payok' },
  { tagValue: 3, paymentMethod: 'qiwi' },
  { tagValue: 3, paymentMethod: 'game-money' },
  { tagValue: 3, paymentMethod: 'web-pay' },

  { tagValue: 3, paymentMethod: 'codi' },
  { tagValue: 3, paymentMethod: 'mexico-local-stores' },
  { tagValue: 3, paymentMethod: 'mexico-online-banking' },
  { tagValue: 3, paymentMethod: 'nu-pay' },
  { tagValue: 3, paymentMethod: 'oxxo' },
  { tagValue: 3, paymentMethod: 'pix' },
  { tagValue: 3, paymentMethod: 'spei' },
];

class CartStore {
  items: CartItem[] = [];
  checkoutInfo: CartCheckoutInfo = {
    availablePaymentMethods: mockedAvailablePaymentMethods,
    isTermsAccepted: false,
    isPaymentValid: true,
    activePaymentMethod: null,
    promocode: '',
    promocodePercentValue: 5,
    accountInfo: {
      isUsingAccountBalance: false,
      accountBalance: 23,
    },
    totalSum: 0,
  };

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'cart',
      properties: ['items'],
      storage: typeof window !== 'undefined' ? localforage : undefined,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: false,
      debugMode: true,
    });
  }

  addToCart(newItem: CartItem) {
    const item = this.items.find(item => item.id === newItem.id);
    if (item) {
      item.count += newItem.count;
    } else {
      this.items.push({ ...newItem, count: 1 });
    }
  }

  isItemInCart(id: number) {
    return this.items.some(item => item.id === id);
  }

  removeFromCart(itemId: number) {
    this.items = this.items.filter(item => item.id !== itemId);
  }

  clearAll() {
    this.items = [];
  }

  setQuantity(itemId: number, count: number) {
    const item = this.items.find(item => item.id === itemId);
    if (item) {
      item.count = count;
    }
  }

  getCartItemsInfo = (): (ProductById | null)[] => {
    return this.items.map(cartItem => {
      const item = [...mockedItemsGroups, ...mockedMM2ItemsGroups].find(item =>
        item.products.some(product => product.id === cartItem.id),
      );

      const product = item?.products.find(
        product => product.id === cartItem.id,
      );

      return product && item
        ? {
            ...item,
            ...product,
            count: cartItem.count,
            status: null,
          }
        : null;
    });
  };

  decreaseQuantity(itemId: number) {
    const item = this.items.find(item => item.id === itemId);
    if (item && item.count > 1) {
      item.count -= 1;
    }
  }

  setPromocode(value: string) {
    this.checkoutInfo.promocode = value;
  }

  toggleTermsAccepted() {
    this.checkoutInfo.isTermsAccepted = !this.checkoutInfo.isTermsAccepted;
    if (this.checkoutInfo.isTermsAccepted) {
      this.checkoutInfo.isPaymentValid = true;
    }
  }

  toggleUsingAccountBalance() {
    this.checkoutInfo.accountInfo.isUsingAccountBalance =
      !this.checkoutInfo.accountInfo.isUsingAccountBalance;
  }

  setPaymentMethod(paymentMethod: CartCheckoutInfo['activePaymentMethod']) {
    this.checkoutInfo.activePaymentMethod = paymentMethod;
  }

  get hasUnavailableItems() {
    return this.items.filter(item => item.status === 'unavailable').length > 0;
  }

  get totalQuantity() {
    return this.items
      .filter(item => item.status !== 'unavailable')
      .reduce((acc, curr) => acc + curr.count, 0);
  }

  validateTermsAndPayment() {
    if (!this.checkoutInfo.isTermsAccepted) {
      this.checkoutInfo.isPaymentValid = false;
    }
  }

  get totalSumm() {
    return this.items.reduce((acc, curr) => acc + curr.price * curr.count, 0);
  }

  async hydrate(data: any): Promise<void> {
    await hydrateStore(data);
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }
}

export const cartStore = new CartStore();
