'use client';
import { CardItemOfferEntity } from '@entities/desktop/cards';
import { CardSameItemEntityMobile } from '@entities/mobile/cards';
import { AddToCartOfferFeature } from '@features/desktop/pet/offer/add-to-cart/ui';
import { AddToCartShoppingItemFeatureMobile } from '@features/mobile/add-to-cart';
import { Offer } from '@widgets/desktop/offer/types';
import React, { useState } from 'react';
import { useStep } from 'usehooks-ts';

import { offerItems } from '@/mocks/offer-items';
import {
  Divider,
  Illustration,
  IllustrationModal,
  ModalContent,
  ModalWrapper,
  TagQuantity,
  Typography,
} from '@/shared/ui';
import { AdditionalInfo } from '@/shared/ui/desktop/additional-info';
import {
  AdditionalInfoMobile,
  AdditionalInfoMobileDefaultIcon,
} from '@/shared/ui/mobile/additional-info';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';
import { useStore } from '@/store/context';

import styles from './cart-item-no-longer-available-modal.module.scss';
export const CartItemNoLongerAvailableModal = ({
  isMobile,
}: {
  isMobile?: boolean;
}) => {
  const [isOpened, setIsOpened] = useState(true);
  const [step, { goToNextStep }] = useStep(2);

  return (
    typeof window !== 'undefined' &&
    window?.showCartItemNoLongerAvailableModal && (
      <ModalWrapper
        hideBottomSheet={step === 2}
        className={step === 1 ? styles.modal : styles['modal--add-item']}
        close={() => setIsOpened(false)}
        isMobile={isMobile}
        isOpened={isOpened}
      >
        <>
          {step === 1 && (
            <CartItemNoLongerAvailableModal.WarningContent
              openAddItemModal={goToNextStep}
              handleClose={() => setIsOpened(false)}
              isMobile={isMobile}
            />
          )}

          {step === 2 && (
            <CartItemNoLongerAvailableModal.AddItem
              isMobile={isMobile}
              handleClose={() => setIsOpened(false)}
            />
          )}
        </>
      </ModalWrapper>
    )
  );
};

CartItemNoLongerAvailableModal.WarningContent = ({
  isMobile,
  handleClose,
  openAddItemModal,
}: {
  isMobile?: boolean;
  handleClose: () => void;
  openAddItemModal: () => void;
}) => {
  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={<ModalContent.CloseButton onClick={handleClose} />}
    >
      <IllustrationModal variant={'purchase-is-over'} />
      <ModalContent.Title
        title={'Oops!'}
        subtitle={'This item is no longer available at this price'}
      />

      <ModalContent.Body>
        {!isMobile ? (
          <AdditionalInfo
            align={'center'}
            width={'full-w'}
            variant={'success'}
            icon={
              <AdditionalInfo.DefaultIcon
                variant={'success'}
                name={'info-square'}
              />
            }
          >
            You can choose a similar item at a different price
          </AdditionalInfo>
        ) : (
          <AdditionalInfoMobile
            width={'full-w'}
            variant={'success'}
            icon={
              <AdditionalInfoMobileDefaultIcon
                variant={'success'}
                name={'info-square'}
              />
            }
          >
            You can choose a similar item at a different price
          </AdditionalInfoMobile>
        )}
      </ModalContent.Body>
      <ModalContent.Footer>
        <ModalContent.Button text={'Add item'} onClick={openAddItemModal} />
        <ModalContent.Button
          variant={'secondary'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalContent.Footer>
    </ModalContent>
  );
};

CartItemNoLongerAvailableModal.AddItem = ({
  isMobile,
  handleClose,
}: {
  isMobile?: boolean;
  handleClose: () => void;
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const game = useStore()?.app.getGame;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [items, setItems] = useState(offerItems[game]);

  const renderOfferItem = (item: Offer, index: number) => (
    <React.Fragment key={`cart-offer-${item?.realName}`}>
      <CardItemOfferEntity
        className={styles['item']}
        {...item}
        bottomSlot={<AddToCartOfferFeature />}
      />
      {index !== items.length - 1 && (
        <Divider style={{ minHeight: 1 }} direction={'horizontal'} />
      )}
    </React.Fragment>
  );

  return isMobile ? (
    <BottomSheetUi
      className={styles['sheet']}
      isOpen={true}
      handleClose={handleClose}
    >
      <BottomSheetUi.Content className={styles['sheet-content']}>
        <div className={styles['items__sheet-header-container']}>
          <BottomSheetUi.Header
            onClick={() =>
              items?.length > 0 ? setItems([]) : setItems(offerItems[game])
            }
          >
            Add item
          </BottomSheetUi.Header>
          <TagQuantity quantity={items.length} />
        </div>
        <div className={styles['items__sheet-container']}>
          {items.length === 0 && (
            <div className={styles['not-found-container']}>
              <IllustrationModal variant={'same-items-not-found'} />
              <div className={styles['not-found-text-mobile']}>
                <Typography className={styles['title']}>
                  No similar items found
                </Typography>
                <Typography className={styles['subtitle']}>
                  Try selecting a different item or changing filter settings
                </Typography>
              </div>
            </div>
          )}
          {Array.isArray(items) &&
            items.length > 0 &&
            items.map((item, idx) => {
              return (
                <CardSameItemEntityMobile
                  key={`${item.realName}-${idx}-offer-mobile`}
                  leftSlot={
                    <CardSameItemEntityMobile.Item
                      img={item.imageUri}
                      rare={item.rare}
                      game={item.game}
                    />
                  }
                  middleSlot={
                    <CardSameItemEntityMobile.ItemInfo
                      price={{
                        current: item.price ?? 0,
                        old: item.price ?? 0,
                      }}
                      title={item.type === 'pet' ? item.type : item.age!}
                      rightSlot={
                        <CardSameItemEntityMobile.ItemProperties
                          flyable={item.flyable}
                          rideable={item.rideable}
                        />
                      }
                    />
                  }
                  rightSlot={
                    <AddToCartShoppingItemFeatureMobile
                      className={styles['sheet__shopping-card-add-to-cart']}
                      item={null}
                    />
                  }
                />
              );
            })}
        </div>
        <BottomSheetUi.Button text={'Close'} onClick={handleClose} />
      </BottomSheetUi.Content>
    </BottomSheetUi>
  ) : (
    <ModalContent className={styles['container--add-item']}>
      <ModalContent.Header
        onClick={() =>
          items?.length > 0 ? setItems([]) : setItems(offerItems[game])
        }
      >
        <ModalContent.HeaderLabel
          className={styles['label--icon']}
          icon={'notes-minimalistic'}
          variant={'primary'}
        >
          Add item
        </ModalContent.HeaderLabel>

        <ModalContent.HeaderText>{items.length} pcs</ModalContent.HeaderText>
      </ModalContent.Header>

      <ModalContent.Body className={styles['items-list']}>
        {items.length === 0 ? (
          <div className={styles['not-found']}>
            <IllustrationModal variant={'same-items-not-found'} />
            <div className={styles['not-found-text']}>
              <Typography className={styles['title']}>
                No similar items found
              </Typography>
              <Typography className={styles['subtitle']}>
                Try selecting a different item or changing filter settings
              </Typography>
            </div>
          </div>
        ) : (
          <ul className={styles['items']}>{items.map(renderOfferItem)}</ul>
        )}
      </ModalContent.Body>
      <ModalContent.Footer>
        <ModalContent.Button text={'Close'} onClick={handleClose} />
      </ModalContent.Footer>
    </ModalContent>
  );
};
