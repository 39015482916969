import cn from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { MouseEvent } from 'react';

import { Button, Illustration } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './add-to-favorite.module.scss';
type AddToCartShoppingItemFeatureProps = {
  itemId: string;
  className?: string;
};

export const AddToFavoriteShoppingItemFeatureMobile = observer(
  ({ itemId, className }: AddToCartShoppingItemFeatureProps) => {
    const favorite = useStore().favorite;
    const isInFavorite = itemId && favorite.favoriteIds.includes(itemId);
    const handleActionButtonClick = (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
      if (itemId) {
        e?.stopPropagation();
        favorite?.toggleFavorite(itemId);
      }
    };

    const btnClasses = cn(
      styles['btn'],
      {
        [styles['liked']]: isInFavorite,
      },
      className,
    );

    return (
      <Button
        as={motion.button}
        whileTap={{ scale: 0.9 }}
        className={btnClasses}
        variant="secondary"
        onClick={handleActionButtonClick}
        iconLeft={
          <Illustration
            name={'heart'}
            spanTagClassName={styles['icon']}
            spriteName={'icons'}
            size={18}
          />
        }
      />
    );
  },
);
