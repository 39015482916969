'use client';
import { CardItemOfferEntity } from '@entities/desktop/cards';
import { CardSameItemEntityMobile } from '@entities/mobile/cards';
import { AddToCartOfferFeature } from '@features/desktop/pet/offer/add-to-cart/ui';
import { AddToCartShoppingItemFeatureMobile } from '@features/mobile/add-to-cart';
import { Offer } from '@widgets/desktop/offer/types';
import React, { useState } from 'react';

import { offerItems } from '@/mocks/offer-items';
import {
  Description,
  Divider,
  IllustrationModal,
  ModalContent,
  ModalWrapper,
  TagQuantity,
  Typography,
} from '@/shared/ui';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';
import { useStore } from '@/store/context';

import styles from './cart-delete-item-modal.module.scss';
export const CartDeleteItemModal = ({
  isMobile,
  handleClose,
  onDelete,
  isOpened,
}: {
  isMobile?: boolean;
  isOpened: boolean;
  handleClose: () => void;
  onDelete: () => void;
}) => {
  return (
    <ModalWrapper
      hideBottomSheet={isMobile}
      className={styles.modal}
      close={handleClose}
      isMobile={isMobile}
      isOpened={isOpened}
    >
      <CartDeleteItemModal.Content
        onDelete={onDelete}
        isMobile={isMobile}
        handleClose={handleClose}
      />
    </ModalWrapper>
  );
};

CartDeleteItemModal.Content = ({
  isMobile,
  handleClose,
  onDelete,
}: {
  isMobile?: boolean;
  handleClose: () => void;
  onDelete: () => void;
}) => {
  return isMobile ? (
    <CartDeleteItemModal.ContentMobile
      onDelete={onDelete}
      isMobile={isMobile}
      handleClose={handleClose}
    />
  ) : (
    <ModalContent
      className={styles['container']}
      topRightSlot={<ModalContent.CloseButton onClick={handleClose} />}
    >
      <IllustrationModal variant={'confirm-item-delete'} />
      <ModalContent.Title
        title={'Delete items!'}
        subtitle={'Items will be removed'}
      />

      <ModalContent.Body>
        <Description
          message={'Are you sure you want to remove items from your cart?'}
        />
      </ModalContent.Body>
      <ModalContent.Footer>
        <ModalContent.Button
          variant={'tertiary'}
          text={'Delete'}
          onClick={onDelete}
        />
        <ModalContent.Button
          variant={'secondary'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalContent.Footer>
    </ModalContent>
  );
};

CartDeleteItemModal.ContentMobile = ({
  isMobile,
  handleClose,
  onDelete,
}: {
  isMobile?: boolean;
  handleClose: () => void;
  onDelete: () => void;
}) => {
  return (
    <BottomSheetUi
      className={styles['sheet']}
      isOpen={true}
      handleClose={handleClose}
    >
      <BottomSheetUi.Content className={styles['sheet-content']}>
        <div className={styles['items__sheet-header-container']}>
          <BottomSheetUi.Header onClick={() => {}}>
            Delete items
          </BottomSheetUi.Header>
        </div>
        <div className={styles['items__sheet-container']}>
          <Typography className={styles['descr']}>
            Are you sure you want to remove items from your cart?
          </Typography>
        </div>
        <div className={styles['btns']}>
          <BottomSheetUi.Button
            variant={'tertiary'}
            text={'Delete'}
            onClick={onDelete}
          />
          <BottomSheetUi.Button
            variant={'secondary'}
            text={'Cancel'}
            onClick={handleClose}
          />
        </div>
      </BottomSheetUi.Content>
    </BottomSheetUi>
  );
};
