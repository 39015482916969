'use client';
import { CheckoutInfoType } from '@entities/desktop/cards/card-checkout-info/ui/card-checkout-info.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { Divider, Typography } from '@/shared/ui';
import { Switch } from '@/shared/ui/desktop/switch';
import { useStore } from '@/store/context';

import { CartPromocode } from './promocode';
import styles from './purchase-checkout.module.scss';
import { CartTotalRow } from './total-row';

export const CartPurchaseCheckoutMobile = observer(() => {
  const cart = useStore()?.cart;
  const isUsingAccountBalance =
    cart?.checkoutInfo?.accountInfo?.isUsingAccountBalance;
  const balanceAmount = cart?.checkoutInfo?.accountInfo?.accountBalance;
  const totalAmount = cart?.totalSumm;
  const itemsInfo = cart?.getCartItemsInfo();
  const checkoutInfo: CheckoutInfoType[] = itemsInfo?.map(item => {
    return {
      title: item!.name,
      type: 'item',
      item: item,
      oldValue: item?.price ?? 0,
      value: item?.price ?? 0,
      flyable: Boolean(item?.flyable),
      rideable: Boolean(item?.rideable),
    };
  });
  const isPromocodeApplied = !!cart?.checkoutInfo?.promocode;
  const isCartEmpty = cart?.items.length <= 0;
  const currencySign = useStore().app.getCurrencySign;

  if (isCartEmpty) {
    return null;
  }

  return (
    <div className={styles['cart-mobile__purchase-checkout']}>
      <div className={styles['partial-payment']}>
        <Typography className={styles['partial-payment__text']}>
          Pay part of the amount from my balance:
        </Typography>
        <Switch
          variant=""
          disabled={!isUsingAccountBalance}
          isChecked={isUsingAccountBalance}
          setIsChecked={() => cart?.toggleUsingAccountBalance()}
        />
      </div>
      <Divider direction={'horizontal'} />

      <div className={styles['total']}>
        {Array.isArray(checkoutInfo) &&
          checkoutInfo.map((item, idx) => (
            <CartTotalRow
              key={`${idx}${item.title}`}
              title={item.title}
              rightSlot={
                <div className={styles['value-container']}>
                  <Typography
                    className={cn(styles['value'], styles['old-value'])}
                  >
                    {!!item.oldValue &&
                      `${currencySign} ${item.oldValue.toFixed(2)}`}
                  </Typography>
                  <Typography
                    className={cn(
                      styles['value'],
                      styles[
                        `total__row-price-${
                          !item.oldValue
                            ? 'neutral'
                            : item.value > item.oldValue
                              ? 'neg'
                              : 'pos'
                        }`
                      ],
                    )}
                    weight={'semi-bold'}
                  >
                    + {currencySign} {item.value.toFixed(2)}
                  </Typography>
                </div>
              }
            />
          ))}

        <CartTotalRow
          title={'Commission'}
          subtitle={'3%'}
          rightSlot={
            <Typography
              className={cn(
                styles['total__row-price'],
                styles['total__row-price-neutral'],
              )}
            >
              + {currencySign} 0.56
            </Typography>
          }
        />

        {isPromocodeApplied && (
          <CartTotalRow
            title={'Promocode'}
            subtitle={'-15%'}
            rightSlot={
              <Typography
                className={cn(
                  styles['total__row-price'],
                  styles['total__row-price-pos'],
                )}
              >
                - {currencySign} 2.89
              </Typography>
            }
          />
        )}

        {isUsingAccountBalance && (
          <CartTotalRow
            title={'My balance'}
            rightSlot={
              <Typography
                className={cn(
                  styles['total__row-price'],
                  styles['total__row-price-pos'],
                )}
              >
                - $ {balanceAmount}
              </Typography>
            }
          />
        )}
      </div>

      <Divider direction={'horizontal'} />

      <CartTotalRow
        title={'Total sum:'}
        rightSlot={
          <Typography
            className={cn(
              styles['total__row-price'],
              styles['total__row-price-sum'],
            )}
          >
            {currencySign}{' '}
            {Number(totalAmount) >= 0 ? Number(totalAmount).toFixed(2) : '0'}
          </Typography>
        }
      />

      <CartPromocode />
    </div>
  );
});
